/* ===================== HEADER ======================*/
.gauto-header-top-area {
  background: #020202 none repeat scroll 0 0;
  color: #eee;
  margin-bottom: 10px;
}

.header-top-left {
  position: relative;
  z-index: 1;
  padding: 10px 0;
}

.header-top-left:before {
  position: absolute;
  background: var(--primary-color) none repeat scroll 0 0;
  content: "";
  top: 0;
  right: 37%;
  width: 2030px;
  height: 120%;
  -webkit-transform: skewX(45deg);
  transform: skewX(45deg);
  z-index: -1;
}

.header-top-right {
  text-align: right;
  padding: 10px 0;
}

.header-top-left p {
  position: relative;
  top: 5px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.header-top-left p svg {
  width: 25px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  background: #fff none repeat scroll 0 0;
  color: var(--primary-color);
  border-radius: 50%;
  margin: 0 2px;
  padding: 5px;
}

.header-top-right>.dropdown {
  display: inline-block;
}

.header-top-right>.dropdown button {
  background: transparent;
  color: #eee;
  border: medium none;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
}

.header-top-right>.dropdown .dropdown-menu.show {
  border-radius: 0;
  border: medium none;
  background: #020202 none repeat scroll 0 0;
  color: #fff;
  margin: 0;
  left: auto !important;
  right: 0 !important;
  padding: 0;
  min-width: 130px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.header-top-right>.dropdown .dropdown-menu a {
  padding: 5px 10px;
  border-bottom: 1px solid #444;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: #fff;
}

.header-top-right>.dropdown .dropdown-menu a:hover {
  background: #2e2e2e none repeat scroll 0 0;
}

.header-top-right>.dropdown .dropdown-menu li img {
  margin-right: 5px;
}

.header-top-right>a {
  color: #eee;
  text-transform: lowercase;
  margin-right: 13px;
}

.header-top-right>a svg {
  margin-right: 5px;
}

.gauto-main-header-area {
  padding: 15px 0;
}

.header-promo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.single-header-promo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: center;
}

.header-promo-icon {
  font-size: 45px;
  margin-right: 10px;
}

.header-promo-info {
  color: #555;
}

.header-promo-info h3 {
  font-size: 20px;
  line-height: 30px;
  color: #001238;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.header-action {
  text-align: right;
}

.header-action a {
  display: inline-block;
  text-align: center;
  padding: 10px 25px 10px 0px;
  background: var(--primary-color) none repeat scroll 0 0;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
  z-index: 1;
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.header-action a:before {
  position: absolute;
  background: var(--primary-color)  none repeat scroll 0 0;
  content: "";
  top: 0;
  left: -25px;
  width: 35%;
  height: 100%;
  -webkit-transform: skewX(45deg);
  transform: skewX(45deg);
  z-index: -1;
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
}

.header-action a i {
  margin-right: 2px;
}

.header-action a:hover{
  background: #020202  none repeat scroll 0 0;
  color: #FFFFFF;
}

.header-action a:hover:before{
  background: #020202  none repeat scroll 0 0;
  color: #FFFFFF;
}

.gauto-mainmenu-area {
  background: #020202 none repeat scroll 0 0;
  border-bottom: 3px solid #363636;
}

.mainmenu ul {
  text-align: left;
}

.mainmenu ul li {
  display: inline-block;
  position: relative;
}

.mainmenu ul li a {
  color: #eee;
  display: block;
  padding: 20px 12px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500 !important;
}

.mainmenu ul li:hover>a,
.mainmenu ul li.active>a {
  color: var(--primary-color) ;
}

.mainmenu ul li ul {
  position: absolute;
  top: 120%;
  left: 0;
  width: 180px;
  background: #fff none repeat scroll 0 0;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.mainmenu ul li:hover ul {
  visibility: visible;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  top: 100%;
}

.mainmenu ul li ul li {
  display: block;
  border-bottom: 1px solid #eee;
}

.mainmenu ul li ul li:last-child {
  border-bottom: 0px solid #eee;
}

.mainmenu ul li ul li a {
  color: #001238;
  padding: 10px 15px;
  display: block;
  font-size: 14px;
}

.mainmenu ul li ul li a:hover {
  padding-left: 20px;
}

.main-search-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: end;
  align-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 13px;
  position: relative;
}

.header-cart-box #dropdownMenu1 {
  border: medium none;
  background: url(/src/img/icon-cart.png) no-repeat scroll 0 0;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: inline-block;
  position: relative;
  top: 8px;
}

.header-cart-box #dropdownMenu1>span {
  position: absolute;
  width: 18px;
  height: 18px;
  background: var(--primary-color) none repeat scroll 0 0;
  font-size: 10px;
  line-height: 20px;
  color: #fff;
  border-radius: 50%;
  right: -6px;
  top: -10px;
  text-align: center;
}

.cart-icon.dropdown-toggle:after {
  visibility: hidden !important;
}

.search-box form {
  position: relative;
  background: #1a1a1a none repeat scroll 0 0;
  height: 40px;
  width: 100%;
  border-radius: 20px;
}

.search-box form input {
  position: absolute;
  left: 0;
  width: 100%;
  border: medium none;
  background: transparent;
  padding: 5px 10px;
  height: 100%;
  color: #eee;
}

.search-box form button {
  position: absolute;
  right: 0;
  background: transparent;
  color: #eee;
  border: medium none;
  height: 100%;
  width: 22%;
  cursor: pointer;
}

.search-box {
  width: 100%;
  margin-left: 20px;
}

.dropdown-menu.cart-dropdown {
  left: auto;
  min-width: 320px;
  right: 0;
  padding: 20px;
  border-radius: 0 !important;
  top: 80px;
  margin-top: -1px;
}

.login .dropdown {
  height: 80px;
  padding: 30px 0;
}

.header-cart-box .dropdown.show .cart-dropdown {
  left: auto !important;
  right: 0px !important;
  border-radius: 0px;
  padding: 20px 20px;
  min-width: 300px;
  margin-top: 45px;
  z-index: 99;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border: 3px solid #ddd;
  -webkit-transform: translate3d(0px, 0px, 0px) !important;
  transform: translate3d(0px, 0px, 0px) !important;
}

.product-remove {
  float: right;
  font-size: 16px !important;
  color: var(--primary-color);
}

.cart-btn-pro-img {
  float: left;
  margin-bottom: 0;
  margin-right: 20px;
  width: 75px;
}

.cart-btn-pro-img>a {
  padding: 0 !important;
}

.cart-dropdown>ul.product_list>li {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.cart-btn-pro-cont h4,
.cart-btn-pro-cont h4 a {
  font-size: 17px;
  line-height: 30px;
  color: #001238;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
}

.cart-btn-pro-cont p {
  color: #777;
  font-size: 14px;
}

.cart-btn-pro-cont span.price {
  color: #111;
  font-weight: 500;
}

.cart-subtotal p {
  color: #444;
  text-align: center;
  margin: 10px 0;
  font-size: 14px;
}

.cart-subtotal p span {
  color: #111;
  font-weight: 500;
  font-size: 16px;
}

.cart-btn {
  text-align: center;
  background: #020202 none repeat scroll 0 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  margin: 15px auto 0;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.cart-btn a {
  display: inline-block;
  text-transform: uppercase;
  padding: 7px 15px;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
}

.cart-btn:after {
  position: absolute;
  content: "";
  width: 66%;
  height: 100%;
  background: var(--primary-color) none repeat scroll 0 0;
  right: -36px;
  z-index: -1;
  -webkit-transform: skewX(40deg);
  transform: skewX(40deg);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.cart-btn:hover {
  background: var(--primary-color) none repeat scroll 0 0;
}

.cart-btn:hover:after {
  background: #020202 none repeat scroll 0 0;
}

.btn-check:active+.btn-success:focus,
.btn-check:checked+.btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-check:focus+.btn-success,
.btn-success:focus {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.btn-check:active+.btn-success,
.btn-check:checked+.btn-success,
.btn-success.active,
.btn-success:active,
.show>.btn-success.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
}

.responsiveMenu {
  display: none;
}

.site-logo img {
  max-height: 81px;
}

@media (min-width: 992px) and (max-width: 1169px) {
  .single-service {
    padding: 25px;
  }

  .service-text h3 {
    font-size: 19px;
  }

  .header-top-left p {
    letter-spacing: 0;
  }

  .header-top-left p i {
    height: 20px;
    width: 20px;
    line-height: 20px;
  }

  .header-promo-info h3 {
    font-size: 17px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .header-top-left:before {
    right: 0;
  }

  .service-menu {
    padding: 20px;
  }

  .service-icon {
    width: 50px;
  }

  .service-text h3 {
    font-size: 17px;
    letter-spacing: 0;
  }

  .single-service {
    margin: 30px 0 0 0;
    border: 0px solid #fff;
    padding: 30px 15px;
  }

  .header-top-left:before {
    right: 0;
  }

  .gauto-responsive-menu {
    display: block;
    width: 50%;
    left: 0;
  }

  .header-action {
    display: none;
  }

  .mainmenu {
    display: none;
  }

  .search-box {
    display: none;
  }

  .main-search-right {
    -webkit-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
    margin-top: 0;
  }

  .header-cart-box #dropdownMenu1 {
    position: relative;
    z-index: 999;
  }

  .gauto-about-area {
    padding-bottom: 20px;
  }

  .gauto-mainmenu-area {
    padding: 15px 0;
    min-height: 60px;
  }

  .header-cart-box {
    position: absolute;
    right: 20px;
    top: 0;
  }

  .responsiveMenu {
    display: block !important;
  }

  .site-logo {
    margin-top: 5px;
  }
}

@media (max-width: 767px) {
  .header-top-left:before {
    display: none;
  }

  .header-top-left {
    display: none;
  }

  .header-top-right {
    text-align: center;
  }

  .gauto-responsive-menu {
    display: block;
  }

  .site-logo {
    text-align: center;
    width: 55%;
    margin: 0 auto;
  }

  .site-logo img {
    width: 100%;
  }

  .gauto-about-area {
    padding-bottom: 50px;
  }

  .header-promo,
  .header-action {
    display: none;
  }

  .gauto-header-top-area {
    margin-bottom: 0;
  }

  .mainmenu {
    display: none;
  }

  .gauto-header-top-area {
    background: #0cdc84 none repeat scroll 0 0;
    border-bottom: 1px solid #eee;
  }

  .brochures a i {
    display: none;
  }

  .header-top-left p,
  .header-top-right>a,
  .header-top-right>.dropdown button {
    color: #001238 !important;
  }

  .header-top-left p i {
    background: var(--primary-color) none repeat scroll 0 0;
    color: #fff;
  }

  .search-box {
    display: none;
  }

  .header-cart-box #dropdownMenu1 {
    float: right;
  }

  .service-text h3 {
    font-size: 19px;
  }

  .header-cart-box {
    width: 95%;
  }

  .login.dropdown {
    position: relative;
    z-index: 999999;
    width: 100%;
  }

  .gauto-mainmenu-area {
    min-height: 50px;
  }

  .header-cart-box .dropdown.show .cart-dropdown {
    margin-top: 35px;
    min-width: 260px;
  }

  .cart-btn-pro-img {
    width: 60px;
    margin-right: 10px;
  }

  .cart-btn-pro-cont {
    overflow: hidden;
  }

  .header-cart-box #dropdownMenu1 {
    top: 0;
  }

  .main-search-right {
    margin-top: 0;
    padding: 7px 0;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .header-top-left:before {
    display: none;
  }

  .header-top-left {
    display: none;
  }

  .header-top-right {
    text-align: center;
  }

  .gauto-responsive-menu {
    display: block;
  }

  .site-logo {
    text-align: center;
    width: 45%;
    margin: 0 auto;
  }

  .gauto-about-area {
    padding-bottom: 50px;
  }

  .site-logo img {
    width: 100%;
  }

  .single-service {
    padding: 20px;
  }

  .service-text h3 {
    font-size: 18px;
    letter-spacing: 0;
  }

  .header-promo,
  .header-action {
    display: none;
  }

  .gauto-header-top-area {
    margin-bottom: 0;
  }

  .mainmenu {
    display: none;
  }

  .gauto-header-top-area {
    background: #fff none repeat scroll 0 0;
    border-bottom: 1px solid #eee;
  }

  .header-top-left p,
  .header-top-right>a,
  .header-top-right>.dropdown button {
    color: #001238;
  }

  .header-top-left p i {
    background: var(--primary-color) none repeat scroll 0 0;
    color: #fff;
  }

  .offer-action:after {
    width: 62%;
  }

  .service-details-image img {
    width: 100%;
  }

  .search-box {
    display: none;
  }

  .header-cart-box #dropdownMenu1 {
    float: right;
  }

  .header-cart-box {
    width: 95%;
  }

  .login.dropdown {
    position: relative;
    z-index: 999999;
    width: 100%;
  }

  .gauto-mainmenu-area {
    min-height: 50px;
  }

  .header-cart-box .dropdown.show .cart-dropdown {
    margin-top: 35px;
    min-width: 260px;
  }

  .cart-btn-pro-img {
    width: 60px;
    margin-right: 10px;
  }

  .cart-btn-pro-cont {
    overflow: hidden;
  }

  .site-logo {
    text-align: center;
    width: 35%;
    margin: 0 auto;
  }

  .gauto-about-area {
    padding-bottom: 50px;
  }

  .site-logo img {
    width: 100%;
  }
}

/* ===================== FOOTER ======================*/
.footer-top-area {
  padding: 50px 0;
  background: url(../img/texture-bg.png) no-repeat scroll 0 0 / cover;
  position: relative;
  z-index: 1;
}

.footer-top-area:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: -1;
}

.single-footer {
  clear: both;
  color: #fff;
  margin-bottom: 15px;
}

.map {
  height: 250px;
  width: 250px;
}

.quick-links {
  float: left;
  width: 45%;
}

.footer-logo {
  margin-bottom: 15px;
  max-width: 200px;
}

.single-footer h3,
.footer-address h3 {
  font-size: 17px;
  color: #eee;
  letter-spacing: 1px;
  margin-bottom: 30px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: block;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  position: relative;
}

.single-footer>h3:after,
.footer-address>h3:after {
  position: absolute;
  content: "";
  bottom: -13px;
  width: 18%;
  height: 2px;
  background: var(--primary-color);
  left: 0;
}

.quick_links {
  overflow: hidden;
}

.quick-links li a {
  color: #FFFFFF;
}

.quick-links li a:hover {
  color: var(--primary-color);
}

.footer-address {
  margin-top: 30px;
}

.newsletter_box {
  margin-top: 30px;
}

.newsletter_box form {
  width: 230px;
  height: 40px;
  background: #222;
  position: relative;
  border-radius: 5px;
}

.newsletter_box form input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #272727;
  height: 100%;
  border: medium none;
  padding: 5px 10px;
  color: #eee;
  border-radius: 5px;
}

.newsletter_box form button {
  position: absolute;
  border: medium none;
  background: transparent;
  right: 10px;
  height: 100%;
  color: var(--primary-color);
  cursor: pointer;
}

.footer-post-image {
  width: 70px;
  float: left;
  margin-right: 10px;
}

.footer-post-image img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.footer-post-text {
  overflow: hidden;
}

.single-footer-post {
  clear: both;
  margin-bottom: 15px;
  overflow: hidden;
}

.footer-post-text h3 {
  color: #eee;
  font-size: 14px;
  margin-bottom: 5px;
  text-transform: capitalize;
  line-height: 23px;
  font-weight: normal;
}

.footer-post-text h3 a {
  color: #aaaeb1;
}

.footer-post-text h3 a:hover {
  color: #eee;
}

.footer-post-text p {
  color: #e97369;
  font-style: italic;
  font-size: 13px;
}

.footer-bottom-area {
  padding: 20px 0;
  background: #020202;
  border-top: 1px solid #333;
}

.copyright p {
  color: #aaa;
  font-size: 14px;
  text-transform: capitalize;
}

.copyright p svg {
  fill: var(--primary-color);
  margin: 0 2px;
}

.copyright p a {
  color: var(--primary-color);
}

.footer-social ul {
  text-align: right;
}

.footer-social ul li {
  display: inline-block;
  margin-left: 10px;
}

.footer-social ul li a {
  display: block;
  color: #eee;
  font-size: 14px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .single-footer {
    margin: 20px auto 20px;
  }

  .newsletter_box form {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .single-footer {
    margin: 20px 0;
  }

  .copyright {
    text-align: center;
    margin-bottom: 10px;
  }

  .footer-social ul {
    text-align: center;
  }

  .newsletter_box form {
    width: 100%;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .single-footer {
    margin: 20px 0;
  }

  .copyright {
    text-align: center;
    margin-bottom: 10px;
  }

  .footer-social ul {
    text-align: center;
  }

  .newsletter_box form {
    width: 100%;
  }
}

/* Responsive */
@media (max-width: 991px) {
  .responsiveMenu {
    display: block !important;
  }

  .spinner-master * {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    box-sizing: border-box;
  }

  .spinner-spin {
    text-indent: -99999px;
    width: 22px;
  }

  .spinner-master {
    position: relative;
    width: 46px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: transparent;
    height: 46px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 9999;
  }

  .spinner:nth-child(2n) {
    width: 70% !important;
  }

  .spinner-master label {
    cursor: pointer;
    height: auto;
    width: 100%;
    padding: 0;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    top: -13px;
    position: relative;
  }

  .spinner-master .spinner {
    height: 2px;
    width: 100%;
    background-color: #ffffff;
  }

  .spinner-master .horizontal {
    margin-top: 6px;
  }

  .spinner-master .diagonal.part-2 {
    margin-top: 6px;
  }

  .spinner-master.active .spinner-spin>.horizontal {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }

  .spinner-master.active .spinner-spin>.diagonal.part-1 {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin-top: 3px;
  }

  .spinner-master.active .spinner-spin>.diagonal.part-2 {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-top: -10px;
  }

  nav#mobileMenu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: block;
    z-index: 9999;
  }

  nav#mobileMenu {
    overflow: hidden;
    max-height: 0;
    background-color: #000;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }

  nav#mobileMenu.active,
  nav#mobileMenu>ul ul.show {
    max-height: 55em;
    overflow: auto;
    padding: 10px 20px;
  }

  nav#mobileMenu>ul ul.show {
    padding: 0;
  }

  nav#mobileMenu>ul>li {
    display: block !important;
    margin: 0 !important;
  }

  nav#mobileMenu>ul>li>a,
  nav#mobileMenu>ul>li>p {
    display: block;
    position: relative;
    padding: 10px 0;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  }

  nav#mobileMenu>ul>li:last-child>a {
    border-bottom: 0px;
  }

  nav#mobileMenu li.has-submenu>a:before,
  .mainmenu ul>li.active a:before {
    display: none;
  }

  .megamenu.active {
    padding: 15px 0 !important;
  }

  .sub-menu:before {
    height: 90% !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
  }

  .sub-menu:after {
    display: none;
  }

  nav#mobileMenu li.has-submenu>a.active:after {
    content: "-";
  }

  nav#mobileMenu ul ul>li a {
    padding: 7px 20px;
    font-size: 16px;
    color: #fff;
  }

  nav#mobileMenu ul ul>li a,
  nav#mobileMenu ul ul>li p {
    padding: 10px 20px;
    line-height: normal;
    -webkit-text-fill-color: #fff;
    display: block;
  }

  .mainmenu ul li ul li {
    border: none;
  }

  nav#mobileMenu>ul>li>a,
  nav#mobileMenu>ul>li>p {
    padding: 10px;
    background: transparent;
    -webkit-text-fill-color: #fff;
    font-size: 14px;
    letter-spacing: 1px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.13);
    text-transform: uppercase;
    font-weight: 500;
  }

  nav#mobileMenu p {
    margin: 0;
    line-height: normal;
  }

  .responsivemenu li p span {
    float: right;
  }
}

/* ===================== ABOUT ======================*/
.about-left h4 {
  font-size: 20px;
  color: var(--primary-color);
  margin-bottom: 10px;
  display: block;
  font-weight: 500;
  text-transform: capitalize;
  font-family: "Rubik", sans-serif;
}

.about-left h2 {
  font-size: 40px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 15px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.about-left p {
  text-align: justify;
}

.about-list ul {
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
  margin-top: 10px;
}

.about-list ul li {
  margin: 5px 0;
}

.about-list ul li span {
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  background: var(--primary-color) none repeat scroll 0 0;
  border-radius: 2px;
  line-height: 20px;
}

.about-list ul li span svg {
  fill: #ffffff;
  height: 12px;
}

.about-signature {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}

.signature-left {
  width: 190px;
  margin-right: 10px;
  padding-right: 10px;
  border-right: 3px solid #f0f0ff;
}

.signature-right h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #001238;
  font-size: 20px;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.about-right {
  margin-top: 30px;
}

@media (min-width: 992px) and (max-width: 1169px) {
  .about-left h2 {
    font-size: 35px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .about-left h2 {
    font-size: 35px;
  }

  .gauto-about-area {
    padding-bottom: 20px !important;
  }
}

@media (max-width: 767px) {
  .about-left h2 {
    font-size: 30px;
  }

  .about-list ul {
    -webkit-columns: 1;
    -moz-columns: 1;
    columns: 1;
  }

  .signature-left {
    width: 130px;
  }

  .gauto-about-area {
    padding-bottom: 50px !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .about-left h2 {
    font-size: 30px;
  }

  .about-list ul {
    -webkit-columns: 1;
    -moz-columns: 1;
    columns: 1;
  }

  .signature-left {
    width: 130px;
  }

  .gauto-about-area {
    padding-bottom: 50px !important;
  }
}

/* ===================== CAR BOOKING ======================*/
/* @import "/node_modules/@syncfusion/ej2-base/styles/material.css";
@import "/node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "/node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "/node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "/node_modules/@syncfusion/ej2-react-calendars/styles/material.css"; */

.gauto-car-booking {
  background: #fbfbfb none repeat scroll 0 0;
}

.rental-tag {
  display: inline-block;
  padding: 5px 15px;
  line-height: 20px;
  text-transform: uppercase;
  background: var(--primary-color) ;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  border-radius: 3px;
  margin-bottom: 5px;
}

.car-booking-right h3 {
  font-size: 30px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 45px;
}

.car-booking-right h4 {
  color: #020202;
  font-size: 18px;
  text-transform: capitalize;
  margin-top: 0px;
  display: inline-block;
}

.car-booking-right h4 span {
  color: #020202;
  font-size: 22px;
}

.price-rent {
  display: inline-block;
  margin-right: 15px;
}

.car-rating {
  display: inline-block;
}

.car-rating ul {
  display: inline-block;
}

.car-rating ul li {
  display: inline-block;
  margin-right: 1px;
  color: #ffcd00;
}

.car-rating p {
  display: inline-block;
  margin-left: 5px;
  color: #001238;
  text-transform: capitalize;
}

.price-rating {
  margin-bottom: 20px;
}

.car-features h3 {
  color: #001238;
}

.car-features ul {
  width: 50%;
  float: left;
  margin-top: 5px;
}

.car-features ul li {
  margin: 0 0;
}

.car-features ul li svg {
  margin-right: 5px;
  fill: var(--primary-color);
}

.single-booking h3,
.booking-right h3 {
  font-size: 23px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 28px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0ff;
}

.single-booking h5 {
  font-size: 20px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 28px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0ff;
}

.single-booking form label {
  display: block;
  color: #001238;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
}

.single-booking form p {
  margin-top: 15px;
}

.single-booking form p input,
.single-booking form p select {
  width: 100%;
  border: 2px solid #f0f0ff;
  padding: 5px 10px;
  height: 45px;
  color: #111;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.single-booking form p textarea {
  width: 100%;
  border: 2px solid #f0f0ff;
  padding: 5px 10px;
  height: 120px;
  color: #111;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.single-booking form p input:focus,
.single-booking form p select:focus {
  border: 2px solid var(--primary-color);
}

.single-booking {
  margin-top: 30px;
}

.single-booking:last-child {
  margin-bottom: 0px;
}

.gauto-payment .payment {
  display: block;
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.gauto-payment .payment input[type="radio"] {
  position: absolute;
  visibility: hidden;
}

.gauto-payment .payment label {
  display: inline-block;
  position: relative;
  padding: 0 0 0 30px;
  margin: 10px auto;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  color: #020202;
  font-weight: 500;
}

.gauto-payment .payment .check {
  display: block;
  position: absolute;
  border: 3px solid #aaaaaa;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  top: 14px;
  left: 0;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

.gauto-payment .payment .check:before {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  height: 8px;
  width: 8px;
  top: 3px;
  left: 3px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

input[type="radio"]:checked~.check {
  border: 3px solid var(--primary-color);
}

input[type="radio"]:checked~.check:before {
  background: var(--primary-color);
}

input[type="radio"]:checked~label {
  color: var(--primary-color);
}

.payment img {
  float: right;
  margin-top: 15px;
}

.action-btn a.gauto-btn {
  color: var(--primary-color);
  margin: 30px 0 0 0;
}

.action-btn a.gauto-btn:hover {
  color: #fff;
}

.action-btn button.gauto-btn {
  color: var(--primary-color);
  margin: 30px 0 0 0;
}

.action-btn button.gauto-btn:hover {
  color: #fff;
}

.car-booking-image img {
  width: 100%;
  max-height: 420px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .car-booking-image img {
    width: 100%;
    max-height: 420px;
  }

  .car-booking-right {
    margin-top: 30px;
  }

  .booking-right {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .car-booking-right {
    margin-top: 30px;
  }

  .car-booking-right h3,
  .product-details-text h3 {
    font-size: 28px;
    line-height: 40px;
  }

  .car-features ul {
    width: 50%;
  }

  .booking-right {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .car-booking-right {
    margin-top: 30px;
  }

  .car-booking-right h3,
  .product-details-text h3 {
    font-size: 28px;
    line-height: 40px;
  }

  .car-features ul {
    width: 33%;
  }

  .booking-right {
    margin-top: 30px;
  }
}

.title-and-value {
  display: flex;
  -webkit-box-pack: space-between;
  -ms-flex-pack: space-between;
  justify-content: space-between;
}

/* ===================== CONTACT ======================*/
.contact-left h3,
.contact-right h3 {
  font-size: 24px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 15px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.contact-left form input {
  width: 100%;
  border: 2px solid #f0f0ff;
  padding: 5px 10px;
  height: 45px;
  color: #111;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  margin: 10px 15px 10px 0px;
}

.contact-left form textarea {
  border: 2px solid #f0f0ff;
  padding: 5px 10px;
  margin: 10px 0;
  color: #111;
  width: 100%;
  height: 135px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.contact-left form input:focus,
.contact-left form textarea:focus {
  border: 2px solid var(--primary-color);
}

.single-contact-field button.gauto-theme-btn {
  width: auto;
  display: inline-block;
  margin-top: 20px;
}

.contact-details p i {
  color: var(--primary-color);
  margin-right: 5px;
}

.single-contact-btn {
  /* display: inline-block; */
  margin-right: 0px;
}

.single-contact-btn h4,
.social-links-contact h4 {
  font-size: 16px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 5px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.single-contact-btn a {
  display: inline-block;
  padding: 10px 16px;
  text-transform: lowercase;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 500;
  background: var(--primary-color) none repeat scroll 0 0;
  border-radius: 4px;
}

.single-contact-btn a:hover {
  background: #000 none repeat scroll 0 0;
  color: #FFFFFF;
}

.single-contact-btn button {
  display: inline-block;
  padding: 10px 16px;
  text-transform: lowercase;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 500;
  background: var(--primary-color) none repeat scroll 0 0;
  border-radius: 4px;
  border: 0px;
}

.single-contact-btn button:hover {
  background: #000 none repeat scroll 0 0;
}

.single-contact-btn-whatsapp button {
  display: inline-block;
  padding: 10px 16px;
  text-transform: lowercase;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 500;
  background: #25D366 none repeat scroll 0 0;
  border-radius: 4px;
  border: 0px;
  margin-right: 10px;
}

.social-links-contact {
  margin-top: 30px;
}

.social-links-contact ul li {
  display: inline-block;
  margin: 0 2px;
}

.social-links-contact ul li a {
  display: block;
  width: 35px;
  height: 35px;
  line-height: 31px;
  text-align: center;
  background: var(--primary-color) none repeat scroll 0 0;
  color: #fff;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  border: 1px solid #ddd;
}

.social-links-contact ul li a:hover {
  background: #000 none repeat scroll 0 0;
  color: #fff;
  border: 1px solid #000;
}

@media (min-width: 768px) and (max-width: 991px) {
  .contact-right {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .contact-right {
    margin-top: 30px;
  }
}

/*************** car listing ***********/
.car_listing_sidebar {
  margin-top: 30px;
  position: sticky;
  top: 30px;
}

.car_listing_sidebar h4 {
  color: #111010;
}

.car_listing_sidebar p {
  color: #111010;
}

.car_listing_nav ul {
  position: relative;
}

.car_listing_nav ul::before {
  content: "";
  position: absolute;
  left: 5px;
  top: 0;
  width: 2px;
  height: 100%;
  background: #111010;
}

.car_listing_nav ul li+li {
  margin-top: 16px;
}

.car_listing_nav ul li a {
  font-weight: 500;
  color: #111010;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  position: relative;
  padding-left: 26px;
}

.car_listing_nav ul li a::before {
  content: "";
  position: absolute;
  left: 1px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background: #111010;
  border-radius: 50%;
  z-index: 2;
}

.car_listing_nav ul li a::after {
  content: "";
  position: absolute;
  left: -3px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  background: #F6F6F6;
  border-radius: 50%;
  border: 1px solid var(--primary-color);
  z-index: 1;
  opacity: 0;
}

.car_listing_nav ul li a:hover {
  color: var(--primary-color);
}

.car_listing_nav ul li a.active {
  color: var(--primary-color);
  font-weight: 600;
}

.car_listing_nav ul li a.active::before {
  background-color: var(--primary-color);
}

.car_listing_nav ul li a.active::after {
  opacity: 1;
}

.car_listing_form .form-btns {
  gap: 24px;
}

.car_listing_form {
  margin-top: 30px;
}

.car_listing_form h5 {
  color: #111010;
}

/* ===================== FIND CAR ======================*/

.gauto-find-area {
  margin-top: -75px;
}

.find-box {
  margin-top: 5%;
  border-radius: 10px;
  background: #fff;
  padding: 13px;
  position: relative;
  z-index: 999;
  box-shadow: 3px 0 29px rgba(0, 0, 0, 0.26);
}

.find-text h3 {
  color: #fff;
  font-size: 33px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  line-height: 40px;
}

.find-form p input,
.find-form p select,
.e-control-wrapper {
  width: 100% !important;
  border: 2px solid #f0f0ff !important;
  padding: 5px 10px;
  height: 45px !important;
  color: #111 !important;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  border: 2px solid var(--primary-color) !important;
}

input#datepicker,
input#timepicker {
  height: auto !important;
  border: none !important;
  font-size: 14px;
  font-weight: 500;
}

.find-form p input:focus,
.find-form p select:focus {
  border: 2px solid var(--primary-color) !important;
}

.find-form form p {
  margin: 0px 0;
  position: relative;
}

.find-form label {
  color: #001238;
  font-weight: 500;
}

.datepicker {
  z-index: 9999;
}

.datepicker {
  border: 2px solid #f0f0ff;
  margin-top: -5px;
}

.datepicker .cell.day.selected,
.datepicker .cell.month.selected,
.datepicker .cell.year.selected,
.datepicker .cell.decade.selected {
  background: #ca3d26 none repeat scroll 0 0;
  color: #fff;
}

.datepicker .cell.day:hover,
.datepicker .cell.month:hover,
.datepicker .cell.year:hover,
.datepicker .cell.decade:hover {
  background: #ca3d26 none repeat scroll 0 0;
  color: #fff;
}

.popover.clockpicker-popover.bottom.clockpicker-align-left {
  border: 2px solid #f0f0ff;
}

.text-primary,
.clockpicker-popover .popover-title {
  color: #ef4836;
}

.datepicker .row.header a {
  margin: 0 3px;
  cursor: pointer;
  display: block;
  text-align: center;
  width: 100%;
}

.datepicker .day {
  font-size: 14px;
  text-align: center;
}

.popover-title span {
  color: #ef4836;
}

.popover-title .text-primary {
  color: #111 !important;
}

.clockpicker input {
  font-size: 14px;
  color: #7c8a97;
}

.clockpicker input:focus {
  box-shadow: 0 0 0 rgba(0, 123, 255, 0.25);
  border: 2px solid #f0f0ff;
  border-radius: 0;
}

@media (min-width: 992px) and (max-width: 1169px) {
  .find-box {
    background: #fff;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .find-box {
    background: #fff;
  }

  .find-text {
    margin-top: 25px;
  }

  .find-text h3 {
    font-size: 25px;
  }

  .find-form form p {
    margin: 10px 0;
  }
}

@media (max-width: 767px) {
  .find-box {
    background: #fff none repeat scroll 0 0;
  }

  .find-text {
    margin-top: 0;
  }

  .find-text h3 {
    color: #001238;
    text-align: center;
    font-size: 25px;
  }

  .find-form {
    margin-top: 5px;
  }

  .find-form form p {
    margin-top: 15px;
    margin-bottom: 0;
  }

  .gauto-find-area {
    margin-top: -25px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .find-box {
    background: #fff none repeat scroll 0 0;
  }

  .find-text {
    margin-top: 0;
  }

  .find-text h3 {
    color: #001238;
    text-align: center;
    font-size: 25px;
  }
}

/* ===================== HERO ======================*/
.gauto-slider-area {
  position: relative;
}

.gauto-main-slide {
  background-color: #eee;
  background-position: center center;
  background-size: cover;
  height: 390px;
}

.slider-content-holder {
  width: 100%;
  text-align: center;
}

.slider-text {
  text-align: left;
}

.gauto-main-caption {
  display: table;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.58) none repeat scroll 0 0;
}

.gauto-caption-cell {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}

.slider-text h2 {
  color: #fff;
  font-size: 65px;
  font-weight: 500;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-transform: capitalize;
  letter-spacing: 3px;
  line-height: 1.2;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
}

.slider-text h2 span {
  color: #fff;
  font-style: italic;
  font-weight: 600;
}

.slider-text p {
  color: #fff;
  font-size: 26px;
  letter-spacing: 2px;
  margin-bottom: 15px;
  text-transform: capitalize;
}

@media (min-width: 768px) and (max-width: 991px) {
  .slider-text h2 {
    font-size: 50px;
  }

  .gauto-main-slide {
    height: 460px;
  }
}

@media (max-width: 767px) {
  .slider-text {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  .slider-text p {
    font-size: 20px;
  }

  .slider-text h2 {
    font-size: 35px;
  }

  .gauto-main-slide {
    height: 127px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .slider-text {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }

  .slider-text p {
    font-size: 20px;
  }

  .slider-text h2 {
    font-size: 35px;
  }

  .gauto-main-slide {
    height: 380px;
  }
}

/* ===================== HOT OFFERS ======================*/
#offerTab {
  text-align: center;
  width: 100%;
  display: block;
  border: medium none;
  margin: 30px 0;
}

#offerTab li {
  display: inline-block;
  margin: 0 5px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: medium none;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: medium none;
}

.nav-tabs .nav-link {
  border: medium none;
}

#offerTab li.nav-item .nav-link,
#offerTab li.nav-item .nav-link.active {
  display: inline-block;
  text-transform: uppercase;
  padding: 7px 25px;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
  position: relative;
  z-index: 1;
}

#offerTab li.nav-item .nav-link:after {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background: #020202 none repeat scroll 0 0;
  z-index: -1;
  -webkit-transform: skew(40deg);
  transform: skew(40deg);
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

#offerTab li.nav-item .nav-link:hover:after {
  background: var(--primary-color) none repeat scroll 0 0;
  -webkit-transform: skew(-40deg);
  transform: skew(-40deg);
}

#offerTab .nav-tabs {
  width: 100%;
  display: block;
  border: medium none;
  margin: 30px 0;
  justify-content: center;
}

#offerTab li.nav-item .nav-link.active:after {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: -1;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background: var(--primary-color) none repeat scroll 0 0;
  -webkit-transform: skew(-40deg);
  transform: skew(-40deg);
}

.single-offers {
  text-align: center;
  padding: 0;
  margin-bottom: 15px;
  margin-top: 0;
  -webkit-box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.3);
}

.offer-image-hot-offers {
  max-width: 100%;
  max-height: 200px;
  width: auto;
  height: auto;
  margin: 0 auto;
  display: block;
  border-radius: 3px;
}

.offer-image-hot-offers img {
  max-width: 100%;
  /* max-height: 200px; */
  width: 100%;
  height: 209px;
  border-radius: 3px;
  object-fit: cover;
}

@media (min-width: 768px) and (max-width: 991px) {
  .offer-image-hot-offers {
    max-width: 100%;
    max-height: 150px;
    width: auto;
    height: auto;
    margin: 0 auto;
    display: block;
    border-radius: 3px;
  }

  .offer-image-hot-offers img {
    max-width: 100%;
    max-height: 150px;
    width: 100%;
    height: auto;
    border-radius: 3px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .offer-image {
    max-width: 100%;
    max-height: 180px;
    width: auto;
    height: auto;
    margin: 0 auto;
    display: block;
    border-radius: 3px;
  }

  .offer-image img {
    max-width: 100%;
    max-height: 180px;
    width: 100%;
    height: 180px;
    border-radius: 3px;
    object-fit: cover;
  }

  .offer-image-hot-offers {
    max-width: 100%;
    max-height: 180px;
    width: auto;
    height: auto;
    margin: 0 auto;
    display: block;
    border-radius: 3px;
  }

  .offer-image-hot-offers img {
    max-width: 100%;
    max-height: 180px;
    width: 100%;
    height: auto;
    border-radius: 3px;
  }
}

@media (max-width: 575px) {
  .offer-image {
    max-width: 100%;
    max-height: 300px;
    width: auto;
    height: auto;
    margin: 0 auto;
    display: block;
    border-radius: 3px;
  }

  .offer-image img {
    max-width: 100%;
    max-height: 300px;
    width: 100%;
    height: auto;
    border-radius: 3px;
  }

  .offer-image-hot-offers {
    max-width: 100%;
    max-height: 300px;
    width: auto;
    height: auto;
    margin: 0 auto;
    display: block;
    border-radius: 3px;
  }

  .offer-image-hot-offers img {
    max-width: 100%;
    /* max-height: 300px; */
    width: 100%;
    height: 270px;
    border-radius: 3px;
    object-fit: cover;
  }
}

.offer-text {
  height: 174px;
  margin-top: 28px;
  padding-left: 10px;
  padding-right: 10px;
}

.offer-text h3 {
  font-size: 10px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 10px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.offer-text h4 {
  color: #020202;
  font-size: 15px;
  margin-top: 8px;
  text-align: center;
}

.offer-text h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-align: center;
  color: #000000;
  font-size: 20px;
  margin-top: 8px;
}

.offer-text p {
  text-align: center;
  color: #111;
}

.offer-text h4 span {
  color: var(--primary-color);
  font-size: 16px;
}

.offer-action {
  text-align: center;
  font-family: "Poppins", sans-serif;
  background-color: rgb(255, 255, 255);
  color: var(--primary-color);
  border: solid 1px;
  border-color: var(--primary-color);
  border-radius: 5px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  margin: 10px auto 0;
}

.offer-action:hover {
  border-radius: 5px;
  color: #ffffff;
  background: var(--primary-color);
  border-color: var(--primary-color);
  border-radius: 5px;
  border: 0;
}

.offer-action a {
  display: inline-block;
  padding: 7px 15px;
  color: var(--primary-color);
  font-size: 14px;
  width: 100%;
  height: 100%;
}

.offer-action a:hover {
  display: inline-block;
  padding: 7px 15px;
  color: #fff;
  font-size: 14px;
  width: 100%;
  height: 100%;
}

.offer-text ul {
  text-align: center;
  margin-top: 10px;
}

.offer-text ul li {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  margin: 0 3px;
  width: 47%;
}

.offer-text ul li svg {
  margin-right: 5px;
  fill: var(--primary-color);
}

@media (min-width: 768px) and (max-width: 991px) {
  .offer-tabs .row .col-lg-4:last-child {
    display: none;
  }

  .offer-tabs .row .col-lg-4,
  .gauto-blog-area .row .col-lg-4 {
    width: 50%;
  }

  .offer-action:after {
    width: 62%;
  }
}

@media (max-width: 767px) {
  #offerTab li {
    display: block;
    margin-bottom: 5px;
  }

  #offerTab li a {
    display: block !important;
  }

  #offerTab li.nav-item .nav-link.active:after,
  #offerTab li.nav-item .nav-link:after {
    -webkit-transform: skewX(0);
    transform: skewX(0);
  }

  #offerTab li.nav-item .nav-link:hover:after {
    -webkit-transform: skewX(0);
    transform: skewX(0);
  }

  .single-offers {
    padding: 0px;
  }

  .offer-text ul li {
    margin: 0 1px !important;
    display: inline-block !important;
  }

  .offer-text ul li i {
    margin-right: 1px;
  }

  #offerTab li.nav-item .nav-link {
    display: block;
    width: 100%;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .offer-action:after {
    width: 62%;
  }

  .single-offers {
    padding: 0px;
  }

  .offer-text ul li {
    margin: 0 3px;
  }

  .offer-text ul li i {
    margin-right: 3px;
  }

  #offerTab li {
    display: block;
    margin-bottom: 5px;
  }

  #offerTab li a {
    display: block !important;
  }

  #offerTab li.nav-item .nav-link.active:after,
  #offerTab li.nav-item .nav-link:after {
    -webkit-transform: skewX(0);
    transform: skewX(0);
  }

  #offerTab li.nav-item .nav-link:hover:after {
    -webkit-transform: skewX(0);
    transform: skewX(0);
  }
}

.hot-offers-btn {
  display: inline-block;
  margin-right: 10px;
  margin-top: 25px;
}

.hot-offers-btn h4 {
  font-size: 16px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 15px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.hot-offers-btn a {
  display: inline-block;
  padding: 10px 16px;
  text-transform: lowercase;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 500;
  background: var(--primary-color) none repeat scroll 0 0;
  border-radius: 4px;
}

.hot-offers-btn a:hover {
  background: #000 none repeat scroll 0 0;
}

/* ===================== SERVICE ======================*/
.gauto-service-area {
  background: #fbfbfd none repeat scroll 0 0;
  padding-bottom: 105px;
}

.service-page-area {
  padding-bottom: 70px;
}

.single-service {
  height: 85%;
  padding: 50px 30px;
  background: #fff none repeat scroll 0 0;
  border: 2px solid #fff;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  position: relative;
  box-shadow: 0px 3px 14px 0px rgba(0, 11, 40, 0.06);
  margin: 30px 5px 5px 5px;
}

.service-page-area .single-service {
  margin: 30px 0 0 0;
}

.service-number {
  font-size: 55px;
  position: absolute;
  top: 25px;
  right: 25px;
  width: 60px;
  height: 55px;
  font-weight: 500;
  line-height: 55px;
  color: rgba(35, 61, 92, 0.07);
  border-radius: 0 0 0 10px;
  z-index: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.service-icon {
  width: 60px;
}

.service-text {
  margin-top: 0px;
  text-align: left;
}

.service-text h3 {
  font-size: 21px;
  color: #001238;
  letter-spacing: 1px;
  margin-bottom: 15px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  display: inline-block;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.service-text a {
  color: #001238;
}

.service-text a:hover,
.service-text h3:hover {
  color: var(--primary-color);
}

.service-slider .slide {
  padding: 0 15px;
}

.service-slider .slick-list {
  margin-left: -15px;
  margin-right: -15px;
}

.service-slider .slick-dots {
  margin-top: 30px;
  position: inherit;
  bottom: 0;
}

.service-slider .slick-dots li button:before {
  display: none;
}

.service-slider .slick-dots li button {
  font-size: 0px;
  line-height: 0;
  display: block;
  width: 6px;
  height: 12px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #020202;
  margin: auto;
}

.service-slider .slick-dots li.slick-active button {
  background: var(--primary-color);
}

.service-slider .slick-dots li {
  width: auto;
}

/* ===================== TESTIMONIAL ======================*/
.gauto-testimonial-area {
  position: relative;
  background: url(../img/fundo-voyager-recomendacoes.jpg) no-repeat fixed 0 0 / cover;
  /* z-index: 1; */
  padding-bottom: 100px;
}

.gauto-testimonial-area:after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8) none repeat scroll 0 0;
  z-index: -1;
}

.gauto-testimonial-area .site-heading h2 {
  color: #fff;
}

.single-testimonial {
  height: auto;
  background: #fff none repeat scroll 0 0;
  padding: 30px;
  margin-top: 30px;
}

.testimonial-text {
  position: relative;
}

.testimonial-text svg {
  position: absolute;
  color: #001238;
  font-size: 80px;
  font-family: Fontawesome;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
}

.testimonial-meta {
  margin-top: 30px;
}

.client-image {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  float: left;
}

.client-image img {
  width: 100% !important;
  height: 100% !important;
  border-radius: 50%;
}

.client-info h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #001238;
  font-size: 20px;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.client-info {
  overflow: hidden;
}

.client-info p {
  color: var(--primary-color);
}

.testimonial-slider .slide {
  padding: 0 15px;
}

.testimonial-slider .slick-list {
  margin-left: -15px;
  margin-right: -15px;
}

.testimonial-slider .slick-dots {
  margin-top: 30px;
  position: inherit;
  bottom: 0;
}

.testimonial-slider .slick-dots li button:before {
  display: none;
}

.testimonial-slider .slick-dots li button {
  font-size: 0px;
  line-height: 0;
  display: block;
  width: 6px;
  height: 12px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #ffffff;
  margin: auto;
}

.testimonial-slider .slick-dots li.slick-active button {
  background: var(--primary-color);
}

.testimonial-slider .slick-dots li {
  width: auto;
}

/* ===================== CAR LIST ======================*/
/* @import "/node_modules/@syncfusion/ej2-base/styles/material.css";
@import "/node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "/node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "/node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "/node_modules/@syncfusion/ej2-react-calendars/styles/material.css"; */

.sidebar-widget form p input,
.sidebar-widget form p select,
.e-control-wrapper {
  width: 100% !important;
  border: 1px solid #f0f0ff !important;
  padding: 5px 10px;
  height: 40px !important;
  color: #111 !important;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  background: #ffffff !important;
  border-radius: 5px;
}

.sidebar-widget form p input:focus,
.sidebar-widget form p select:focus {
  border: 2px solid var(--primary-color);
}

.sidebar-widget form {
  background: #f4f4f5 none repeat scroll 0 0;
  border-radius: 5px;
  border-color: #444;
  padding: 20px;
}

.sidebar-widget h3 {
  color: var(--primary-color);
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  padding: 5px 0px 15px 5px;
  background: #f4f4f5 none repeat scroll 0 0;
}

.sidebar-widget form p {
  margin-bottom: 10px;
}

.sidebar-widget form p:last-child {
  margin-bottom: 0;
}

.sidebar-widget form p .nice-select.open .list {
  width: 100%;
}

.sidebar-widget form p button {
  margin-top: 20px;
  background: #fff none repeat scroll 0 0;
}

.property-page-heading {
  border: 0px solid #f0f0ff;
  padding: 7px 15px;
  background: #f4f4f5 none repeat scroll 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.propertu-page-shortby {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.propertu-page-shortby .nice-select {
  background-color: #f4f4f5;
  border-radius: 0;
  border: solid 0px #e8e8e8;
  width: 200px;
}

.propertu-page-head {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.propertu-page-head ul li {
  display: inline-block;
  margin-right: 10px;
}

.propertu-page-head ul li.active a {
  color: #111;
}

.paging_status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.propertu-page-head ul li a:hover,
.paging_status p {
  color: #111;
}

.propertu-page-shortby label {
  margin-top: 5px;
  margin-bottom: 0;
  margin-right: 10px;
  color: #111;
  font-size: 14px;
}

.propertu-page-shortby label i {
  margin-right: 5px;
}

.sidebar-widget {
  margin-bottom: 30px;
}

.sidebar-widget:last-child {
  margin-bottom: 0;
}

.service-menu h3 {
  color: var(--primary-color);
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  padding: 5px 0px 15px 0px;
  background: #f4f4f5 none repeat scroll 0 0;
}

.service-menu li {
  font-family: "Poppins", sans-serif;
  margin-bottom: 5px;
  font-size: 12px;
  margin: 0px;
}

.service-menu {
  border-radius: 5px;
  padding: 20px;
  background: #f4f4f5 none repeat scroll 0 0;
}

.service-menu li:last-child {
  margin-bottom: 0;
}

.service-menu li a {
  display: block;
  padding: 10px 20px;
  background: #fff none repeat scroll 0 0;
  color: #001238;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}

.service-menu li a span {
  margin-left: 5px;
}

.service-menu li.active a,
.service-menu li a:hover {
  color: var(--primary-color);
  padding-left: 25px;
}

.car-grid-list .single-offers {
  text-align: center;
  padding: 0;
  margin-bottom: 15px;
  margin-top: 0;
  -webkit-box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.3);
}

.offer-image {
  max-width: 100%;
  max-height: 180px;
  width: auto;
  height: auto;
  margin: 0 auto;
  display: block;
  border-radius: 3px;
}

.offer-image img {
  max-width: 100%;
  /* max-height:180px; */
  width: 100%;
  height: 200px;
  border-radius: 3px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .offer-image {
    max-width: 100%;
    max-height: 150px;
    width: auto;
    height: auto;
    margin: 0 auto;
    display: block;
    border-radius: 3px;
  }

  .offer-image img {
    max-width: 100%;
    max-height: 150px;
    width: 100%;
    height: 150px;
    border-radius: 3px;
    object-fit: cover;
  }
}

.offer-text ul li i {
  margin-right: 5px;
  color: var(--primary-color);
}

.pagination-box-row {
  text-align: left;
}

.pagination-box-row p {
  display: inline-block;
  font-size: 15px;
  color: #444;
  margin-right: 15px;
}

.pagination {
  margin-top: 20px;
  text-align: center;
  float: none;
  display: inline-block;
}

.pagination li {
  display: inline-block;
  margin-right: 5px;
}

.pagination li:last-child {
  margin-right: 0;
}

.pagination li a {
  display: block;
  width: 35px;
  height: 35px;
  font-size: 15px;
  line-height: 35px;
  border-radius: 5px;
  color: #111 !important;
  font-weight: 500;
}

.pagination li.active a,
.pagination li a:hover {
  background: #101010 none repeat scroll 0 0;
  color: #fff !important;
}

@media (min-width: 768px) and (max-width: 991px) {
  .car-listing-right {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .car-listing-right {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .car-listing-right {
    margin-top: 30px;
  }
}

.accordion-button {
  background-color: white !important;
  font-weight: 500;
  font-size: 14px;
  padding: 10px;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: var(--primary-color)  !important;
}

.accordion-button:not(.collapsed)::after {
  color: var(--primary-color)  !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ec3323'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-item {
  margin-bottom: 10px;
}

/*--------------------------------------------------------------
#0.1    Theme Reset Style
--------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Poppins:500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i&display=swap");

body {
  font-size: 14px;
  line-height: 26px;
  font-style: normal;
  color: #7c8a97;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  height: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin: 0;
}

.no-pad-left {
  padding-left: 0;
}

.no-pad-right {
  padding-right: 0;
}

.no-pad-all {
  padding: 0;
}

.fix {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  color: #6b739c;
  text-decoration: none;
}

a:focus {
  text-decoration: none;
  outline: medium none;
  color: #6b739c;
}

a:hover {
  color: inherit;
  text-decoration: none;
  color: inherit;
}

input:focus,
textarea:focus,
button:focus,
select:focus {
  outline: medium none;
}

:-moz-placeholder {
  color: #7c8a97;
  text-transform: capitalize;
}

::-moz-placeholder {
  color: #7c8a97;
  text-transform: capitalize;
}

:-ms-input-placeholder {
  color: #7c8a97;
  text-transform: capitalize;
}

::-webkit-input-placeholder {
  color: #7c8a97;
  text-transform: capitalize;
}

:-ms-select-placeholder {
  color: #7c8a97;
  text-transform: capitalize;
}

::-webkit-select-placeholder {
  color: #7c8a97;
  text-transform: capitalize;
}

:-ms-textarea-placeholder {
  color: #7c8a97;
  text-transform: capitalize;
}

::-webkit-textarea-placeholder {
  color: #7c8a97;
  text-transform: capitalize;
}

tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

.gauto-btn,
button.gauto-theme-btn {
  color: #fff;
  background: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: var(--primary-color);
  position: relative;
  margin: 1em;
  display: inline-block;
  padding: 8px 15px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.gauto-btn:before,
.gauto-btn:after,
button.gauto-theme-btn:before,
button.gauto-theme-btn:after {
  content: "";
  display: block;
  position: absolute;
  border-color: var(--primary-color);
  box-sizing: border-box;
  border-style: solid;
  width: 1em;
  height: 1em;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.gauto-btn:before,
button.gauto-theme-btn:before {
  top: -6px;
  left: -6px;
  border-width: 2px 0 0 2px;
  z-index: 5;
}

.gauto-btn:after,
button.gauto-theme-btn:after {
  bottom: -6px;
  right: -6px;
  border-width: 0 2px 2px 0;
}

.gauto-btn:hover:before,
.gauto-btn:hover:after,
button.gauto-theme-btn:hover:before,
button.gauto-theme-btn:hover:after {
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  border-color: var(--primary-color);
}

.gauto-btn:hover,
button.gauto-theme-btn:hover {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

button.gauto-theme-btn {
  color: var(--primary-color);
  background: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: var(--primary-color);
  position: relative;
  display: block;
  padding: 8px 15px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  margin: 0;
  width: 100%;
}

button.gauto-theme-btn:before,
button.gauto-theme-btn:after {
  content: "";
  display: block;
  position: absolute;
  border-color: var(--primary-color);
  box-sizing: border-box;
  border-style: solid;
  width: 1em;
  height: 1em;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

button.gauto-theme-btn:hover:before,
button.gauto-theme-btn:hover:after {
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  border-color: var(--primary-color);
}

.site-heading {
  padding-bottom: 45px;
  margin-bottom: 30px;
  width: 60%;
  margin: 0 auto;
  text-align: center;
}

.site-heading h4 {
  font-size: 20px;
  color: var(--primary-color);
  margin-bottom: 10px;
  display: block;
  font-weight: 500;
  text-transform: capitalize;
  font-family: "Rubik", sans-serif;
}

.section_100 {
  padding: 100px 0;
}

.section_70 {
  padding: 70px 0;
}

.section_50 {
  padding: 50px 0;
}

.section_15 {
  padding: 15px 0;
}

.pad-right {
  padding-right: 0;
}

.section_t_100 {
  padding-top: 100px;
}

.section_b_70 {
  padding-bottom: 70px;
}

.section_b_80 {
  padding-bottom: 80px;
}

.btntoTop:before {
  color: #fff;
  content: "ï„‚";
  display: inline-block;
  font: 20px "FontAwesome";
  vertical-align: middle;
}

.btntoTop {
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  bottom: 70px;
  cursor: pointer;
  height: 50px;
  line-height: 47px;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  position: fixed;
  right: 20px;
  text-align: center;
  -webkit-transition: opacity 0.4s ease 0s;
  transition: opacity 0.4s ease 0s;
  width: 50px;
  z-index: 99999;
}

.btntoTop.active {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.btntoTop.active:hover {
  background: #212121 none repeat scroll 0 0;
  border: 2px solid #cb2415;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
}

.btntoTop.active.btntoTop:hover:before {
  color: #fff;
}

@media (max-width: 767px) {
  .site-heading {
    width: 100%;
  }

  .site-heading h2 {
    font-size: 30px;
  }

  .pad-right-sm {
    padding-right: 15px;
  }

  .pad-left-sm {
    padding-left: 15px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .site-heading {
    width: 100%;
  }

  .site-heading h2 {
    font-size: 30px;
  }
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.form-select {
  display: block;
  width: 100%;
  font-size: 0.9375rem;
  font-weight: 400;
  color: #575757;
  background-color: #fff;
  border-radius: 0.375rem;
  height: 40px;
}

.form-select:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}

.grecaptcha-badge {
  display: none;
  z-index: 1;
}

.cookiealert {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0 !important;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  transform: translateY(100%);
  transition: all 500ms ease-out;
  color: var(--primary-color);
  background: #000;
}

.cookiealert.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
  transition-delay: 1000ms;
}

.cookiealert a {
  text-decoration: underline;
  color: var(--primary-color);
}

.cookiealert .acceptcookies {
  margin-left: 10px;
  vertical-align: baseline;
}

.btn-primary {
  background-color: #cb2415;
  border: 0px;
}

.btn-primary:hover {
  background-color: #cb2415;
  border: 0px;
}

.watch-video-button {
  display: inline-flex;
  align-items: center;
  position: absolute;
  left: 20px;
  bottom: 20px;
  padding: 12px 20px;
  background: #cb2415;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  z-index: 10;
}

@media (max-width: 576px) {
  .watch-video-button {
    display: inline-flex;
    align-items: center;
    position: absolute;
    left: 0px;
    bottom: 0px;
    padding: 6px 10px;
    background: var(--bs-primary);
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 600;
    z-index: 10;
    border-bottom-left-radius: 0.3125rem;
  }
}

.watch-video-button a {
  color: #FFFFFF;
}

.watch-video-button-i {
  margin-right: 10px !important;
}

.video-popup-btn {
  width: 60px;
  height: 60px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.video-popup-btn:hover {
  -webkit-transform: translateY(-6px);
  transform: translateY(-6px);
}

.video-popup-btn.bg-primary {
  -webkit-animation: 1.2s ripple-animation linear infinite;
  animation: 1.2s ripple-animation linear infinite;
}

.img-slide-fluid {
  width: 342px;
  height: 356px;
  object-fit: cover;
}

.img-slide-thumb-fluid {
  max-width: 184px;
  height: 100px;
  object-fit: cover;
}

@media (max-width: 576px) {
  .img-slide-fluid {
    width: 342px;
    height: 276px;
    object-fit: cover;
  }
}

@media (min-width: 576px) {
  .img-slide-thumb-fluid {
    max-width: 184px;
    height: 100px;
    object-fit: cover;
  }
}

/* Share Social Media */
@media only screen and (min-width: 480px) and (max-width: 9999px) {

  .modal-whats {
    margin-bottom: 5px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: #fff;
    width: 60px;
    height: 65px;
    background-color: #26d367;
    text-decoration: none;
  }

  .modal-facebook {
    margin-bottom: 5px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px !important;
    color: #fff !important;
    width: 60px;
    height: 65px;
    background-color: #3b5999 !important;
  }

  .modal-instagram {
    margin-bottom: 5px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: #fff !important;
    width: 60px;
    height: 65px;
    background: -webkit-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -webkit-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%) !important;
    background: -moz-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -moz-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%) !important;
    background: -ms-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -ms-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%) !important;
    background: -o-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -o-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%) !important;
    background: radial-gradient(circle farthest-corner at 32% 106%, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), linear-gradient(135deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%) !important;
  }

  .modal-youtube {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px !important;
    color: #fff !important;
    width: 60px;
    height: 65px;
    background-color: #FF0000 !important;
  }

  .box {
    position: fixed;
    right: 0px;
    top: 25%;
    z-index: 55;
    display: block;
  }

  .share {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: transparent;
    border-top-left-radius: 13px;
    border-bottom-left-radius: 14px;
  }

  .share a {
    text-decoration: none !important;
    color: #fff;
  }

  .share a:hover,
  .share a:focus {
    text-decoration: none !important;
  }

  .share a:first-child {
    border-radius: 10px 0px 0px 0px !important;
  }

  .share a:last-child {
    border-radius: 0px 0px 0px 10px !important;
  }

}

@media only screen and (max-width: 480px) {

  .modal-whats {
    margin-bottom: 2px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: #fff;
    width: 40px;
    height: 50px;
    background-color: #26d367;
    text-decoration: none;
  }

  .modal-facebook {
    margin-bottom: 2px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px !important;
    color: #fff !important;
    width: 40px;
    height: 50px;
    background-color: #3b5999 !important;
  }

  .modal-instagram {
    margin-bottom: 2px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: #fff !important;
    width: 40px;
    height: 50px;
    background: -webkit-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -webkit-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%) !important;
    background: -moz-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -moz-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%) !important;
    background: -ms-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -ms-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%) !important;
    background: -o-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -o-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%) !important;
    background: radial-gradient(circle farthest-corner at 32% 106%, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), linear-gradient(135deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%) !important;
  }

  .modal-youtube {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px !important;
    color: #fff !important;
    width: 40px;
    height: 50px;
    background-color: #FF0000 !important;
  }

  .box {
    position: fixed;
    right: 0px;
    top: 25%;
    z-index: 55;
    display: block;
  }

  .share {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: transparent;
    border-top-left-radius: 13px;
    border-bottom-left-radius: 14px;
  }

  .share a {
    text-decoration: none !important;
    color: #fff;
  }

  .share a:hover,
  .share a:focus {
    text-decoration: none !important;
  }

  .share a:first-child {
    border-radius: 10px 0px 0px 0px !important;
  }

  .share a:last-child {
    border-radius: 0px 0px 0px 10px !important;
  }

  .auto-hide {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .auto-hide.hidden {
    opacity: 0;
    pointer-events: none;
  }

}

.h3-about-section h1 {
  color: #001238;
}

.h3-about-section p {
  color: #001238;
  margin-top: 15px;
}

.h3-about-section a {
  color: #cb2415;
}