@media (max-width: 576px) {
    .img-card-fluid .offer-image-hot-offers img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    width: 8px;
    height: 10px;
    border-radius: 4px;
    background-color: #bcbcbc;
  }

  :root {
    --primary-color: #10d483 ;
  }